import React from 'react';
import { Button, Typography } from 'antd';
import './Lccs.css';
import { executeLccs, statusLccs, uploadFilesLccs } from '../../utils/AWS/AWSRequest';
import * as loginPersistance from '../AuthWrapper/persistance';

const { Title } = Typography;

/**
 * LCCS画面component
 * @property {File[]} selectedFiles 選択ファイル配列
 * @property {function} isCorrectFile 選択ファイル設定関数
 * @property {function} getStatus LCCS実行と状態取得用関数
 */
class Lccs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFiles: null,
      selectedNumDict: {}
    }
    this.isCorrectFile = this.isCorrectFile.bind(this)
  }

  // アップロードファイル選択処理
  isCorrectFile(event, extension, num) {
    // fileList型のstateを取得
    let files = this.state.selectedFiles;
    // ボタンとファイルの関係辞書stateを取得
    let numList = this.state.selectedNumDict;
    const fileName = event.target.files[0].name;
    // ファイルの拡張子が違う場合エラー
    if (fileName.split('.').pop() !== extension) {
      alert("ファイルの拡張子が正しくありません。\n指定拡張子：" + extension);
      return
    }
    // fileListを新しく作成
    const newFileList = new DataTransfer();
    // stateがnullの場合、failListと関係辞書に値を格納
    if (files == null) {
      files = Array.from(event.target.files)
      files.forEach(file => {
        newFileList.items.add(file);
        numList[num] = file.name;
      })
      // すでにstateにファイルがある場合
    } else {
      if (num in this.state.selectedNumDict && num in [1, 2]) {
        if (num === 1) {
          alert("必須ファイル(.yml)は選択済みです\nファイル名：" + this.state.selectedNumDict[num])
        } else if (num === 2) {
          alert("必須ファイル(.csv)は選択済みです\nファイル名：" + this.state.selectedNumDict[num])
        }
        return
      }
      let filesArray = Array.from(files)
      // 同ファイル名が選択された場合alertを出す
      let isSameName = false
      filesArray.forEach((file, index) => {
        if (file.name === fileName) {
          isSameName = true
        }
      })
      if (isSameName) {
        alert("同じ名前のファイルが選択されています\nファイル名：" + this.state.selectedNumDict[num])
        return
      }
      // 選択されたファイルを新しいfileListに追加
      filesArray.push(event.target.files[0])
      filesArray.forEach(file => {
        newFileList.items.add(file);
        numList[num] = file.name;
      })
    }
    // 新しいfileListでstateを更新
    this.setState({
      selectedFiles: newFileList.files,
      selectedNumDict: numList
    })
  }

  // ファイル選択解除処理
  handleRemoveFile(fileName) {
    let files = this.state.selectedFiles;
    let numList = this.state.selectedNumDict;
    // fileListを新しく作成
    const newFileList = new DataTransfer();
    if (files != null) {
      const filesArray = Array.from(files)
      // 削除ボタンが押されたファイルを新しいfileListに追加しない
      filesArray.forEach(file => {
        if (file.name !== fileName) {
          newFileList.items.add(file);
        }
      })
    }
    for (let key in numList) {
      if (numList[key] === fileName) {
        delete numList[key]
      }
    }
    // fileListにファイルがなければstateをnullにする
    if (newFileList.files.length == 0) {
      this.setState({
        selectedFiles: null
      })
      // ファイルがあればstateを更新
    } else {
      this.setState({
        selectedFiles: newFileList.files,
        selectedNumDict: numList
      })
    }
  }

  // ファイルアップロード処理 Lccs実行とステータス取得
  async getStatus(selectedFiles) {
    const lccsButton = document.getElementById('lccsButton')
    const loadingContainer = document.getElementById('loadingContainer')
    // 実行ボタンを非活性にし、実行中メッセージを表示する
    lccsButton.disabled = true;
    loadingContainer.style.display = 'inline'

    // ファイルが選択されていないときalertを出す
    if (selectedFiles == null) {
      // 実行ボタンを活性状態に戻し、実行中メッセージを非表示にする
      lccsButton.disabled = false;
      loadingContainer.style.display = 'none'
      alert("ファイルが選択されていません")
      return
    }
    // 必須ファイルが選択されていないときalertを出す
    const checkKey = [1, 2]
    if (!checkKey.every(key => key in this.state.selectedNumDict)) {
      lccsButton.disabled = false;
      loadingContainer.style.display = 'none'
      alert("必須ファイルが選択されていません")
      return
    }
    // 選択されたファイルの中にymlファイルがあるかを判定する
    let existsYml = false
    let selectedFileName
    for (let i = 0; i < selectedFiles.length; i++) {
      const selectedFile = selectedFiles[i].name
      if (selectedFile.endsWith('.yml') == true) {
        existsYml = true
        selectedFileName = selectedFile
      }
    }
    // もしymlファイルがなければエラー
    if (!existsYml) {
      // 実行ボタンを活性状態に戻し、実行中メッセージを非表示にする
      lccsButton.disabled = false;
      loadingContainer.style.display = 'none'
      alert("ymlファイルの選択は必須です")
      return
    }
    const result = await uploadFilesLccs(loginPersistance.getAuthResultProjectName(), selectedFiles)
    if (!result) {
      lccsButton.disabled = false
      loadingContainer.style.display = 'none'
      return
    }
    const s3path = result["path"]
    if (result != undefined && result["message"] == "Success") {
      this.setState({
        selectedFiles: null
      })
    }

    const arn = await executeLccs(loginPersistance.getAuthResultProjectName(), s3path, selectedFileName)
    const ecsStatus = await statusLccs(arn['executionArn'])
    let errorMessage = undefined;
    if (ecsStatus === 400) {
      errorMessage = "LCCS実行に失敗しました。アップロードファイルを確認してください。"
    } else if (ecsStatus === 408) {
      errorMessage = "LCCSを実行中です。しばらくしてから実行してください。"
    }
    // 実行ボタンを活性状態に戻し、実行中メッセージを非表示にする
    lccsButton.disabled = false
    loadingContainer.style.display = 'none'

    return errorMessage
  }

  render() {
    const selectedFiles = this.state.selectedFiles;
    return (
      <div className="lccs-top-container">
        <div className="lccs-title">
          <Title level={4}>LifeCycleCostシミュレーション操作</Title>
        </div>
        <div className="lccs-container">
          <div className="lccs-button">
            <span className="select-file">
              <label className="button-label" >
                <input type="file" value="" style={{ display: "none" }} onChange={(event) => this.isCorrectFile(event, "yml", 1)} />
                設定ファイル(.yml) 選択
              </label>
              <span className="required">[必須]</span>
            </span>
            <span className="explanatory">LifeCycleCostシミュレーションの設定ファイル</span>
            <br />
            <span className="select-file">
              <label className="button-label" >
                <input type="file" value="" style={{ display: "none" }} onChange={(event) => this.isCorrectFile(event, "csv", 2)} />
                劣化予測ファイル(.csv) 選択
              </label>
              <span className="required">[必須]</span>
            </span>
            <span className="explanatory">シミュレーションに使用する劣化予測モデル用ファイル</span>
            <br />
            <span className="select-file">
              <label className="button-label" >
                <input type="file" value="" style={{ display: "none" }} onChange={(event) => this.isCorrectFile(event, "csv", 3)} />
                予算ファイル(.csv) 選択
              </label>
            </span>
            <span className="explanatory">シミュレーションに使用する予算ファイル</span>
            <br />
            <span className="select-file">
              <label className="button-label disabled" >
                <input type="file" disabled value="" style={{ display: "none" }} onChange={(event) => this.isCorrectFile(event, "csv", 4)} />
                修繕計画ファイル(.csv) 選択
              </label>
            </span>
            <span className="explanatory disabled">修繕計画策定再実行時に使用する修繕計画ファイル</span>
            {selectedFiles && selectedFiles.length > 0 && (
              <div>
                <h3>選択されたファイル：</h3>
                <ul>
                  {Array.from(selectedFiles).map((file, index) => (
                    <li key={index}>
                      {file.name + " "}
                      <Button type="button" className="delete-button" onClick={() => this.handleRemoveFile(file.name)}>削除</Button>
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div className="lccs-button">
            <Button
              id="lccsButton"
              type="primary"
              onClick={() => this.getStatus(selectedFiles).then((errorMessage) => {
                if (errorMessage !== undefined) {
                  alert(errorMessage);
                }
              })}
            >
              LCCシミュレーション実行
            </Button>
            <div id="loadingContainer" className="loading-container">
              <img id="loadingImage" className="loading-image" src="./img/ajax-loader.gif" alt="実行中" />
              <span id="loadingText" className="loading-text">実行中です…</span>
            </div>
          </div>
          <div className="lccs-button">
            <Button
              disabled
              type="primary">
              ファイル削除
            </Button>
          </div>
          <div className="lccs-button">
            <Button
              disabled
              type="primary">
              リネーム
            </Button>
          </div>
        </div>
      </div>
    );
  };
}

export default Lccs;
